import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/combineLatest';
import 'rxjs/add/operator/concat';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/debounce';
import 'rxjs/add/operator/distinct';
import 'rxjs/add/operator/toArray';
import 'rxjs/add/operator/toPromise';

import { AppComponent } from './app.component';
import { ArticlesComponent } from './views/articles/articles/articles.component';
import { AppRoutingModule } from './app-routing.module';
import { HeaderComponent } from './shared/header/header/header.component';
import { HomeComponent } from './views/home/home/home.component';
import { ArticleService } from './services/article.service';
import { AuthenticationService } from './services/authentication.service';
import { AngularMaterialModule } from './modules/angular-material.module';
import { SidebarComponent } from './shared/sidebar/sidebar/sidebar.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ArticleFilterComponent } from './shared/article-filter/article-filter.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { MatIconModule } from '@angular/material';
import { CreateArticleComponent } from './views/articles/articles/create-article/create-article.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthHttpInterceptor } from './services/http-interceptor';
import { CategoryService } from './services/category.service';
import { DynamicInputComponent } from './shared/dynamic-input/dynamic-input.component';
import * as hammerjs from 'hammerjs';

@NgModule({
  declarations: [
    AppComponent,
    ArticlesComponent,
    CreateArticleComponent,
    HeaderComponent,
    HomeComponent,
    SidebarComponent,
    ArticleFilterComponent,
    DynamicInputComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    AngularMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    HttpClientModule,
    LoggerModule.forRoot({serverLoggingUrl: '/api/logs', level: NgxLoggerLevel.DEBUG, serverLogLevel: NgxLoggerLevel.ERROR}),
    // environment.production ? ServiceWorkerModule.register('ngsw-worker.js') : []
  ],
  providers: [AuthenticationService, ArticleService, CategoryService, HttpClientModule,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
