export class ArticleAttribute {

  format: AttributeFormat;
  key_values: any; // json with possible values and keys as short version
  enum_values: Array<string>;
  kind: AttributeKind;
  localizable: boolean;
  readonly: boolean;
  required: boolean;
  sortable: boolean;
  static: boolean;
  type: AttributeType;
  max_value: number;
  min_value: number;
  unit: string;

  constructor(obj?: any) {
    this.format = obj && obj.format || null;
    this.key_values = obj && obj.key_values || null;
    this.enum_values = obj && obj.enum_values || null;
    this.kind = obj && obj.kind || null;
    this.localizable = obj && obj.localizable || null;
    this.readonly = obj && obj.readonly || null;
    this.required = obj && obj.required || null;
    this.sortable = obj && obj.sortable || null;
    this.static = obj && obj.static || null;
    this.type = obj && obj.type || null;
    this.max_value = obj && obj.max_value || null;
    this.min_value = obj && obj.min_value || null;
    this.unit = obj && obj.unit || null;
  }
}

export enum AttributeFormat {
  'STRING' = 'string',
  'INTEGER' = 'integer',
  'DATE' = 'date'
}

export enum AttributeKind {
  'FREE' = 'FREE',
  'KEY' = 'KEY',
  'ENUM' = 'ENUM',
  'AUTO' = 'AUTO'
}

export enum AttributeType {
  'integer',
  'number',
  'string'
}
