export class Article {
  public boot_stiffness: number;
  public boot_style: string;
  public condition: string;
  public created: string;
  public delivery_methods: string;
  public description: string;
  public disciplines: string;
  public hits: number;
  public kite_bar_line_length: number;
  public kite_bar_width: number;
  public kite_lines: number;
  public kite_sail_includes_bar: string;
  public kite_sail_shape: string;
  public kite_sail_squaremeter: number;
  public location: string;
  public manufacturer: string;
  public model_year: number;
  public modified: string;
  public name: string;
  public price: number;
  public riding_levels: string;
  public sexes: string;
  public size: string;
  public ski_stick_length: number;
  public snow_wood_flex: number;
  public snow_wood_profile: string;
  public snowboard_binding_stiffness: number;
  public snowboard_board_shape: string;
  public unread_messages: number;
  public wood_binding_style: string;
  public wood_includes_binding: string;
  public wood_length: number;
  public wood_waist_width: number;

  constructor(obj?) {
    this.boot_stiffness = obj && obj.boot_stiffness || null;
    this.boot_style = obj && obj.boot_style || null;
    this.condition = obj && obj.condition || null;
    this.created = obj && obj.created || null;
    this.delivery_methods = obj && obj.delivery_methods || null;
    this.description = obj && obj.description || null;
    this.disciplines = obj && obj.disciplines || null;
    this.hits = obj && obj.hits || null;
    this.kite_bar_line_length = obj && obj.kite_bar_line_length || null;
    this.kite_bar_width = obj && obj.kite_bar_width || null;
    this.kite_lines = obj && obj.kite_lines || null;
    this.kite_sail_includes_bar = obj && obj.kite_sail_includes_bar || null;
    this.kite_sail_shape = obj && obj.kite_sail_shape || null;
    this.kite_sail_squaremeter = obj && obj.kite_sail_squaremeter || null;
    this.location = obj && obj.location || null;
    this.manufacturer = obj && obj.manufacturer || null;
    this.model_year = obj && obj.model_year || null;
    this.modified = obj && obj.modified || null;
    this.name = obj && obj.name || null;
    this.price = obj && obj.price || null;
    this.riding_levels = obj && obj.riding_levels || null;
    this.sexes = obj && obj.sexes || null;
    this.size = obj && obj.size || null;
    this.ski_stick_length = obj && obj.ski_stick_length || null;
    this.snow_wood_flex = obj && obj.snow_wood_flex || null;
    this.snow_wood_profile = obj && obj.snow_wood_profile || null;
    this.snowboard_binding_stiffness = obj && obj.snowboard_binding_stiffness || null;
    this.snowboard_board_shape = obj && obj.snowboard_board_shape || null;
    this.unread_messages = obj && obj.unread_messages || null;
    this.wood_binding_style = obj && obj.wood_binding_style || null;
    this.wood_includes_binding = obj && obj.wood_includes_binding || null;
    this.wood_length = obj && obj.wood_length || null;
    this.wood_waist_width = obj && obj.wood_waist_width || null;
  }
}
