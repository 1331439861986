import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { NGXLogger } from 'ngx-logger';
import { CategoryService } from '../../../../services/category.service';
import { Category } from '../../../../models/category.class';
import { DynamicFormService } from '../../../../services/dynamic-form.service';
import { ArticleAttribute } from '../../../../models/attribute.class';

@Component({
  selector: 'app-create-article',
  templateUrl: './create-article.component.html',
  styleUrls: ['./create-article.component.scss']
})
export class CreateArticleComponent implements OnInit, OnDestroy {

  mainCategory = new FormControl();
  subCategory = new FormControl();
  articleForm: FormGroup;
  articleAttributes = new Map<string, ArticleAttribute>();
  articleKeys: Array<any> = [];

  constructor(private _logger: NGXLogger,
              private cdRef: ChangeDetectorRef,
              private _fb: FormBuilder,
              public categoryService: CategoryService,
              private dynamicFormService: DynamicFormService) {
  }

  ngOnInit() {
    this.subscribeChangesOfCategory();
  }

  ngOnDestroy() {
  }

  subscribeChangesOfCategory(): void {
    this.mainCategory.valueChanges.subscribe(change => {
      if (!change) {
        this.subCategory.disable();
        this.subCategory.patchValue('');
      } else {
        this.subCategory.enable();
      }
    });
    this.subCategory.valueChanges
      .subscribe((category: Category) => {
        console.log('changed', category);
        if (!category) {
          this.articleKeys = [];
        } else {
          this.categoryService.loadCategoryOptions(category.category_ref).subscribe(
            (attributes: any) => {
              const formForCategory: FormGroup = DynamicFormService.attributesToFormGroup(attributes);
              this.buildForm(category.category_ref, formForCategory);
              Object.keys(attributes).forEach(
                (key: string) => this.articleAttributes.set(key, attributes[key])
              );
              this.articleKeys = Array.from(this.articleAttributes.keys());
              this._logger.debug('[CreateArticleComponent] generated formGroup: ', formForCategory, attributes, this.articleKeys);
            });
        }
      });
  }

  displayCategoryWith(category?: Category): string | undefined {
    return !!category ? category.name : undefined;
  }

  saveArticle() {
    if (this.articleForm.valid) {

      this._logger.debug('post is valid: ', this.articleForm.valid, this.articleForm);

    } else {
      this._logger.warn('post is valid: ', this.articleForm.valid, this.articleForm);
    }
  }

  private buildForm(categoryRef: string, formForCategory: FormGroup): void {
    this.articleForm = this._fb.group({
      categoryRef: [categoryRef],
      categoryAttributes: formForCategory,
    });
  }
}
