import { Component, Input, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../services/authentication.service';
import { MatSidenav } from '@angular/material';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Input() sidebar: MatSidenav;

  constructor(public authenticationService: AuthenticationService) { }

  ngOnInit() {
    this.authenticationService.isAuthenticated.subscribe();
  }

  open() {
    this.sidebar.open();
  }

}
