export class Category {
  public category_ref: string;
  public name: string;
  public title: string;
  public subcategories: Array<Category>;

  constructor(obj) {
    this.category_ref = obj && obj.category_ref || null;
    this.name = obj && obj.name || null;
    this.title = obj && obj.title || null;
    this.subcategories = obj && obj.subcategories || [];
  }
}
