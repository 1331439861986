import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MatAutocompleteModule,
  MatButtonModule,
  MatCardModule,
  MatCheckboxModule,
  MatDialogModule,
  MatInputModule,
  MatMenuModule,
  MatProgressBarModule,
  MatRadioModule, MatSelectModule,
  MatSidenavModule, MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatTooltipModule
} from '@angular/material';

const modules = [
  MatAutocompleteModule,
  MatButtonModule,
  MatCheckboxModule,
  MatCardModule,
  MatInputModule,
  MatDialogModule,
  MatProgressBarModule,
  MatRadioModule,
  MatSidenavModule,
  MatSnackBarModule,
  MatSlideToggleModule,
  MatSliderModule,
  MatSelectModule,
  MatTooltipModule,
  MatMenuModule,
];

@NgModule({
  imports: [
    CommonModule,
    ...modules
  ],
  declarations: [],
  exports: [...modules]
})
export class AngularMaterialModule { }
