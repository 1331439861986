import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AttributeFormat, AttributeKind } from '../../models/attribute.class';

@Component({
  selector: 'app-dynamic-input',
  templateUrl: './dynamic-input.component.html',
  styleUrls: ['./dynamic-input.component.scss']
})
export class DynamicInputComponent implements OnChanges {

  @Input() kind: AttributeKind;
  @Input() keyValues: any;
  @Input() placeholder: string;
  @Input() format: AttributeFormat;
  @Input() unit: string;
  @Input() minValue: number;
  @Input() maxValue: number;
  dynamicInput = new FormControl();
  values = [];

  AttributeKind = AttributeKind;
  AttributeFormat = AttributeFormat;

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.keyValues && !!changes.keyValues.currentValue && changes.kind ) {
      this.kind = changes.kind.currentValue;
      this.values = Object.keys(changes.keyValues.currentValue)
        .map((key) => changes.keyValues.currentValue[key]);
    }
  }
}
