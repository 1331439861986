import { Component, Input, OnInit, Output } from '@angular/core';

export enum ARTICLE_FILTER_TYPE {
  PRICE = 1,
  BRAND = 2,
  YEAR = 3
}

@Component({
  selector: 'app-article-filter',
  templateUrl: './article-filter.component.html',
  styleUrls: ['./article-filter.component.scss']
})
export class ArticleFilterComponent implements OnInit {
  static getFilterName(filter: ARTICLE_FILTER_TYPE) {
    switch (filter) {
      case ARTICLE_FILTER_TYPE.PRICE:
        return 'Preis';
      case ARTICLE_FILTER_TYPE.BRAND:
        return 'Marke';
      case ARTICLE_FILTER_TYPE.YEAR:
        return 'Herstellungsdatum';
    }
  }

  @Input() articleList: Array<any>;
  @Output() filteredArticleList: Array<any>;

  constructor() { }

  ngOnInit() {
  }


}
