import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Observable } from 'rxjs/Observable';

@Injectable()
export class AuthenticationService {

  isAuthenticated = new BehaviorSubject(false);

  constructor() { }

  public login(email: string, password: string): Observable<any> {
    this.isAuthenticated.next(true);
    // TODO return user object here?
    return this.isAuthenticated;
  }

  public logout(): Observable<boolean> {
    this.isAuthenticated.next(false);
    return this.isAuthenticated;
  }

}
