import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Observable } from 'rxjs/Observable';
import { HttpClient, HttpParams } from '@angular/common/http';
import { NGXLogger } from 'ngx-logger';
import { Article } from '../models/article.class';

@Injectable()
export class ArticleService {

  private _articles = new BehaviorSubject(new Map<string, Article>());

  constructor(private _http: HttpClient, private _logger: NGXLogger) {
    this.loadArticles();
  }

  public observeArticles(): Observable<Array<Article>> {
    return this._articles.map(
      (articles: Map<string, any>) => {
        return Array.from(articles.values());
    });
  }

  public createArticle(article: Article): Observable<Article> {
    return this._http.post(`https://api.alpha.brettlos.com/v1/article/create`, article)
      .map(obj => new Article(obj));
      /*.catch((error: any, caught: Observable<Article>) => {
        this._logger.error('[ArticleService] unable to creat article: ', error);
        return caught;
      });*/
  }

  private loadArticles(queryParameters?: HttpParams): void {
    const query = new HttpParams();
    query.append('limit', '50');
    query.append('size', 'S,M,L');
    // ?limit=50&size=S%2CM%2CL
    const sub = this._http.get(`https://api.alpha.brettlos.com/v1/article/search/de`, {params: query})
      .map((response: any) => response.payload.results)
      .map(results => results.map(result => new Article(result)))
      .subscribe((results: Array<Article>) => {
          this._logger.debug('[ArticleService] loaded articles: ', results);
          this.updateArticleCache(results);
      });
  }

  private updateArticleCache(update: Array<Article>): void {
    const articleCache = this._articles.getValue();
    update.forEach((article: Article) => articleCache.set('1', article));
    this._articles.next(articleCache);
  }

}
