import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ArticlesComponent } from './views/articles/articles/articles.component';
import { HomeComponent } from './views/home/home/home.component';
import { CreateArticleComponent } from './views/articles/articles/create-article/create-article.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'overview',
    pathMatch: 'full',
  },
  {
    path: 'overview',
    component: HomeComponent,
  },
  {
    path: 'articles',
    component: ArticlesComponent
  },
  {
    path: 'articles/create-article',
    component: CreateArticleComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
