import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Observable } from 'rxjs/Observable';
import { HttpClient } from '@angular/common/http';
import { Category } from '../models/category.class';
import { ArticleAttribute } from '../models/attribute.class';

const CATEGORY_URL = `https://api.alpha.brettlos.com/v1/category/`;

@Injectable()
export class CategoryService {

  private categories = new BehaviorSubject<Array<Category>>(null);

  constructor(private _http: HttpClient) {
    this.loadCategories();
  }

  public observeCategories(): Observable<Array<Category>> {
    return this.categories
      .map(cat => {
        return cat;
      })
      .filter(exists => !!exists);
  }

  // returns an object with entries of type attribute (class.ts)
  public loadCategoryOptions(categoryRef: string): Observable<any> {
    return this._http.get(`${CATEGORY_URL}options/de/${categoryRef}`)
      .map((response: any) => response.payload.attributes);
  }

  private loadCategories(): void {
    this._http.get(`${CATEGORY_URL}list/de`)
      .map((response: any) => response.payload.categories)
      .map(categories => this.castToCategory(categories))
      .subscribe(
        (categories: Array<Category>) => {
          this.categories.next(categories);
        }
      );
  }

  private castToCategory(objects: Array<any>): Array<Category> {
    return objects.map(obj => {
      const category = new Category(obj);
      if (category.subcategories.length > 0) {
        category.subcategories = this.castToCategory(category.subcategories);
      }
      return category;
    });
  }
}
