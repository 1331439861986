import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/catch';


@Injectable()
export class AuthHttpInterceptor implements HttpInterceptor {
  constructor() { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    console.log('intercepted request ... ');

// Clone the request to add the new header.
    req = req.clone({ headers: req.headers.set('Content-Type', 'application/json')});
    req = req.clone({ headers: req.headers.set('X-B-token', 'tobi')});
    console.log('Sending request with new header now ...', req.headers);

// send the newly created request
    return next.handle(req)
      .catch((error, caught) => {
// intercept the respons error and displace it to the console
        console.error('"Error Occurred', error);
// return the error to the method that called it
        return Observable.throw(error);
      }) as any;
  }
}
