import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class DynamicFormService {

  static attributesToFormGroup(attributes: any): FormGroup {
    const formGroup = new FormGroup({});

    Object.keys(attributes).forEach(
      (key: string) => {
          formGroup.addControl(key, new FormControl());
          if (attributes[key]['required']) {
            formGroup.controls[key].setValidators(Validators.required);
          }
      }
    );
    return formGroup;
  }

  constructor() { }

}
